// https://bo.wix.com/wix-docs/fe-guild/viewer-platform/reference/site#viewer-platform_reference_site_getcustomizedurlsegments
import { CustomUrlSegments } from './UrlSegments';
import { UrlMappingsKeys } from '@wix/url-mapper-utils';
import {
  ApiTypesV1GroupResponse,
  isGroupPrivate,
  isJoined,
} from '@wix/social-groups-api';
import { Tab } from './Tab';
import { Location, SiteApi } from './types';
import { getGroupSection } from './getGroupUrlSegments';

async function buildGroupUrl(
  location: Location,
  siteApi: SiteApi,
  { groupId, tabName }: CustomUrlSegments,
) {
  const section = await getGroupSection(siteApi);
  const baseUrl = location.baseUrl;
  return `${baseUrl}/${section}/${groupId}/${tabName}`;
}

// https://bo.wix.com/wix-docs/fe-guild/viewer-platform/reference/location#viewer-platform_reference_location_buildcustomizedurl
export async function getGroupUrl(
  location: Location,
  siteApi: SiteApi,
  segments: CustomUrlSegments,
) {
  let url: string | undefined;
  try {
    url = await location.buildCustomizedUrl?.(
      UrlMappingsKeys.GROUP,
      segments as any,
      {
        baseUrl: location.baseUrl,
      },
    );
  } catch (e) {
    console.log('[getGroupUrl] Failed');
  }
  if (!url) {
    url = await buildGroupUrl(location, siteApi, segments);
  }
  return url;
}

export function getTabForGroup(group: ApiTypesV1GroupResponse) {
  let tabName = Tab.DISCUSSION;
  if (!isJoined(group as any) && isGroupPrivate(group)) {
    tabName = Tab.ABOUT;
  }
  return tabName;
}
